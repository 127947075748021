/* External dependencies */
import React from 'react';

/* Local dependencies */
import { IconProps } from './types';

export default function SmailIconLeft({
  className,
  width = 80,
  height = 100,
}: IconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 59 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.1488 108.761C44.9649 107.129 34.2579 102.081 25.4318 94.7764C16.5149 87.5329 9.5476 77.8892 5.23367 67.2371C0.935269 56.5787 -0.759765 44.8063 0.619267 33.4058C1.89226 22.0202 6.08545 10.9566 12.9956 2.01875L13.1777 1.78079C13.869 0.889355 15.1533 0.696212 16.0509 1.35205C16.8186 1.90989 17.0532 2.91037 16.674 3.7423C14.5183 8.49317 12.7554 13.3933 11.59 18.4127C10.3634 23.4147 9.65519 28.5189 9.51133 33.6157C9.39288 38.7127 9.67522 43.8089 10.5772 48.8019C11.4581 53.7963 12.8812 58.6838 14.8045 63.3694C16.6813 68.0738 19.055 72.5774 21.8901 76.7829C24.7152 80.9994 28.0547 84.8613 31.6799 88.4468C35.3295 92.012 39.384 95.1933 43.7434 97.94C48.0721 100.745 52.7453 103.043 57.5986 104.964C58.6399 105.376 59.1223 106.543 58.6772 107.569C58.2982 108.446 57.3616 108.936 56.4483 108.805L56.1488 108.761Z"
        fill="#6574CF"
      />
    </svg>
  );
}
