/* External dependencies */
import { graphql } from 'gatsby';
import React from 'react';

/* Local dependencies */
import Header from '@components/website/header/Header';
import ErrorComponent from '@shared/404/errorComponent';
import Layout from '@shared/ui/layout/WebLayout';

export default function NotFoundPage() {
  return (
    <Layout>
      <Header />
      <ErrorComponent />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
