/* External dependencies */
import SmailIconLeft from '@svg/smailIconLeft';
import SmailIconRight from '@svg/smailIconRight';
import { Button, Container, Div, Text } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

/* Local dependencies */

export default function ErrorComponent() {
  const data = useStaticQuery(queryImg);
  const notFoundErrorIcon = getImage(data?.notFountError);
  const { language } = useI18next();

  return (
    <Div
      d="flex"
      align="center"
      justify="center"
      minH="100vh"
      bg="linear-gradient(90deg, rgba(140, 250, 199, 0.2) 0%, rgba(207, 214, 250, 0.2) 100%, rgba(207, 214, 250, 0.2) 100%), #FFFFFF"
      p={{ t: '140px' }}
    >
      <Container>
        <Div fontFamily="primary" d="flex" justify="center" align="center">
          <Div textAlign="center" d="flex" flexDir="column" align="center">
            <Div maxW="500px" d="flex" pos="relative" m={{ b: '70px' }}>
              <Div
                d={{ xs: 'none', md: 'block' }}
                bottom="-30px"
                left="-60px"
                pos="absolute"
              >
                <SmailIconLeft />
              </Div>
              <GatsbyImage image={notFoundErrorIcon!} alt="404" />
              <Div
                d={{ xs: 'none', md: 'block' }}
                top="-30px"
                right="-60px"
                pos="absolute"
              >
                <SmailIconRight />
              </Div>
            </Div>
            <Text tag="h4" textSize={{ xs: 'display2', sm: 'errorTitle' }}>
              <Trans>errorTitle</Trans>
            </Text>
            <Text tag="h4" textColor="#8F9BB3" m={{ y: '20px' }}>
              <Trans>errorDescription</Trans>
            </Text>
            <Link to="/" language={language}>
              <Button
                bg="#6574CF"
                maxW="300px"
                w="100%"
                d="inlene-block"
                m={{ t: '10px' }}
                textAlign="center"
              >
                <Trans>toMainPageText</Trans>
              </Button>
            </Link>
          </Div>
        </Div>
      </Container>
    </Div>
  );
}

export const queryImg = graphql`
  query {
    notFountError: file(relativePath: { eq: "notFoundError.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
