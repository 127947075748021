/* External dependencies */
import React from 'react';

// Local dependencies
import { IconProps } from './types';

export default function SmailIconRight({
  className,
  width = 80,
  height = 100,
}: IconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 81 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.87235 1.65904C14.0136 -0.241989 25.7576 1.25225 36.4105 5.47395C47.1309 9.60938 56.7393 16.6296 64.1361 25.4282C71.52 34.2375 76.7725 44.9103 78.9863 56.1787C81.2965 67.4002 80.7297 79.2171 76.9216 89.8514L76.822 90.134C76.4403 91.1952 75.2785 91.7755 74.222 91.429C73.3194 91.1355 72.7869 90.2564 72.8903 89.3481C73.4714 84.1641 73.6327 78.9593 73.1889 73.8258C72.8087 68.6898 71.9039 63.6167 70.4646 58.725C69.0011 53.8408 67.1567 49.0813 64.7549 44.6112C62.3726 40.1332 59.5078 35.9244 56.2297 32.0621C52.9901 28.1675 49.3398 24.6173 45.343 21.4931C41.3523 18.3554 36.9821 15.7138 32.4255 13.4232C27.8521 11.1595 23.0123 9.386 18.0168 8.11992C13.0327 6.78925 7.87758 6.0467 2.66785 5.71838C1.54999 5.64775 0.730511 4.68743 0.836408 3.57373C0.925506 2.62235 1.66471 1.86698 2.57405 1.71011L2.87235 1.65904Z"
        fill="#00D68F"
      />
    </svg>
  );
}
